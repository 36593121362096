import { graphql, Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"

const SiteMap = ({ data }) => {
  const menus = data.glstrapi.menus

  const excludeMenus = [
    "home",
    "book-a-viewing",
    "register-interest",
    "make-an-enquiry",
    "send-us-your-cv",
  ]

  const menuObj = {}
  menus.forEach(menu => {
    if (menu.parent) {
      if (menuObj[menu.parent.label]) {
        menuObj[menu.parent.label].push({
          ...menu,
          slug: menu.parent.slug + "/" + menu.slug,
        })
      } else {
        if (menu.parent.slug === "careers") return
        menuObj[menu.parent.label] = [
          {
            ...menu,
            slug: menu.parent.slug + "/" + menu.slug,
          },
        ]
      }
    }
  })

  const menuWithoutChildren = menus.filter(menu => !menu.parent)

  const menuWithoutChildrenFiltered = menuWithoutChildren.filter(menu => {
    if (menuObj[menu.label]) {
      return false
    } else if (excludeMenus.includes(menu.slug)) {
      return false
    }
    return true
  })

  return (
    <div className="sitemap-wrap">
      <Header layout="static_template" />
      <div className=" sitemap-container margin-for-header section-p">
        <h1 className="title">Sitemap</h1>
        <div className="sitemap-section">
          {Object.keys(menuObj).map((key, index) => (
            <div className="sitemap_menu-section">
              <h3 className="sitemap-title">{key}</h3>
              <ul className="sitemap-menu">
                {menuObj[key].map((menu, index) => (
                  <li className="sitemap-menu-item" key={index}>
                    <Link to={"/" + menu.slug + "/"}>{menu.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {menuWithoutChildrenFiltered.map((menu, index) => (
            <div className="sitemap_menu-section">
              <Link to={"/" + menu.slug + "/"} className="sitemap-title">
                {menu.label}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SiteMap

export const pageQuery = graphql`
  query GetAllMenus {
    glstrapi {
      menus {
        label
        id
        slug
        parent {
          label
          slug
          parent {
            slug
            label
            parent {
              slug
              label
            }
          }
        }
      }
    }
  }
`
